<template>
  <div class="app-box">
    <x-search placeholder="请输入员工姓名" @click.native="searchEvent"></x-search>
    <div class="list-index-box">
      <x-list-index isAssignment tip="暂无数据" @itemClick="itemClick"></x-list-index>
    </div>
  </div>
</template>

<script>
import listIndex from "@/components/listIndex";
import search from "@/components/customerCenter/search";

export default {
  data () {
    return {};
  },
  created () {
    document.title = "";
    document.title = "客户分配";
  },
  methods: {
    searchEvent () {
      this.$router.push({
        path: "/searchPage/customerAssignment"
      });
    },
    itemClick (item) {
      this.$router.push({
        path: `/customerAssignment/resourceList/${item.id}`
      });
    }
  },
  components: {
    [listIndex.name]: listIndex,
    [search.name]: search
  }
};
</script>

<style scoped>
.list-index-box {
  position: absolute;
  top: 0.52rem;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f6f8fb;
}
</style>
